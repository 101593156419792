/* Common: Grid
--------------------------------------------------------------------------------------------------------------------- */

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;

  @include responsive-down(tablet) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include responsive-down(mobile-large) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &--large {
    max-width: 1920px;
    padding-left: 30px;
    padding-right: 30px;

    @include responsive-down(mobile-large) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &--medium {
    max-width: 1250px;

    @include responsive-up(desktop-large) {
      max-width: 1550px;
    }
  }

  &--small {
    max-width: 1080px;

    @include responsive-up(desktop-large) {
      max-width: 1390px;
    }
  }

  &--section-left {
    max-width: 1250px;
    margin-left: calc((100vw - 1250px) / 2);
  }

  &--mobile-large-15 {
    @include responsive-down(mobile-large) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
