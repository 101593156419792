/* Layouts: Header
--------------------------------------------------------------------------------------------------------------------- */
.header {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  position: fixed;
  z-index: 20;
  max-width: 100% !important;
  top: 0;

  @include transition(all 0.2s ease-in);

  @include responsive-down(mobile) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__brand {
    width: 220px;
    height: 27px;
    z-index: 2;

    @include responsive-down(mobile-large) {
      z-index: 2;
    }

    @include responsive-down(mobile) {
      width: 200px;
    }

    @include responsive-down(mobile-extra-small) {
      width: 190px;
    }

    img {
      width: 100%;
    }

    .cls-1 {
      fill: black;
    }
  }

  &--white {
    position: absolute;

    &::before {
      content: none;
    }

    .menu {
      &-burger {
        span {
          background-color: white !important;
        }
      }
    }

    .header__brand {
      .cls-1 {
        fill: white;
      }
    }
  }

  .menu {
    width: 40px;
    height: 30px;
    position: relative;

    &::before {
      content: "";
      background-color: black;
      position: absolute;
      height: 50px;
      width: 50px;
      bottom: -11px;
      right: -12px;
      z-index: 0;
      opacity: 0;
      transform: scale(0.2);
      transition: transform 0.45s cubic-bezier(0.34, 2, 0.64, 1), opacity 0.6s cubic-bezier(0.33, 1, 0.68, 1), -webkit-transform 0.45s cubic-bezier(0.34, 2, 0.64, 1), -moz-transform 0.45s cubic-bezier(0.34, 2, 0.64, 1), -o-transform 0.45s cubic-bezier(0.34, 2, 0.64, 1);

      @include transition (all 0.5s ease 0s);
    }

    &-burger {
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: relative;
      z-index: 2;

      span {
        position: absolute;
        height: 2px;
        width: 25px;
        right: 0;
        background-color: $black;
        border-radius: 12px;
        opacity: 1;
        transition: all 300ms ease-in;
        z-index: 2;
      }

      &__top {
        top: 6px;

        @include transform(rotate(0));

        @include responsive-down(mobile) {
          top: 3px;
        }
      }

      &__mid {
        opacity: 1;
        top: calc(50% - 5px);

        @include responsive-down(mobile) {
          top: calc(50% - 8px);
        }
      }

      &__bot {
        width: 18px !important;
        right: 0;
        top: calc(100% - 15px - 1px);
        transition: all 300ms ease-in;

        @include transform(rotate(0));

        @include responsive-down(mobile) {
          top: calc(100% - 15px - 3px);
        }
      }
    }

    &-before {
      width: 100%;
      height: 100%;
      position: fixed;
      right: -100%;
      top: 0;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $black02;
      }
    }

    &-box {
      position: fixed;
      text-align: right;
      top: 0;
      right: -100%;
      width: 640px;
      height: 100%;
      padding: 120px 160px 60px 0;
      list-style: none;
      background-color: $white;
      box-shadow: 1px 0 6px rgba(0, 0, 0, 0.2);
      transition-duration: 1.5s;
      display: flex;
      flex-direction: column;

      ul {
        list-style: none;
        margin: 0;
      }

      li {
        padding: 0;
      }

      @include responsive-down(tablet) {
        width: 540px;
        padding: 120px 150px 120px 0;
      }

      @include responsive-down(tablet-small) {
        width: 500px;
        padding: 120px 130px 120px 0;
      }

      @include responsive-down(mobile-extra-large) {
        width: 420px;
        padding: 120px 90px 120px 0;
      }

      @include responsive-down(mobile-large) {
        width: 100%;
        padding: 70px 20px 70px 0;
      }

      &__item {
        color: $text-menu;
        font-family: $font-operetta;
        font-size: 40px;
        font-weight: 600;
        text-decoration: none;
        transition-duration: 0.25s;
        position: relative;
        display: inline-flex;
        transform: translateY(-20px);
        opacity: 0;

        @include transition (all 0.4s ease-in);

        @include responsive-down(tablet) {
          font-size: 36px;
        }

        @include responsive-down(tablet-small) {
          font-size: 32px;
        }

        @include responsive-down(mobile-large) {
          font-size: 36px;
        }

        &::before {
          content: "";
          position: absolute;
          height: 1px;
          width: 0;
          background-color: $black;
          bottom: 16px;

          @include transition (all 0.4s ease-in);
        }

        @include responsive-up(tablet) {
          &:hover {
            color: $black;

            &::before {
              content: "";
              width: 100%;

              @include transition (all 0.4s ease-in);
            }
          }
        }

        @include responsive-down(tablet) {
          &:focus {
            color: $black;

            &::before {
              content: "";
              width: 100%;

              @include transition (all 0.4s ease-in);
            }
          }
        }
      }

      &__social {
        margin-top: auto;

        @include responsive-down(mobile-large) {
          margin-top: 20px;
        }

        .iconsocial {
          padding-left: 20px;
          color: $mid-grey;
          font-size: 18px;

          @include responsive-up(tablet) {
            &:hover {
              color: black;
            }
          }

          @include responsive-down(tablet) {
            &:focus {
              color: black;
            }
          }

          @include responsive-down(mobile) {
            font-size: 25px;
          }
        }
      }
    }

    &--opened {
      .menu-burger {
        z-index: 30;
        position: fixed;

        span {
          background-color: black !important;
        }

        .menu-burger__top {
          top: calc(50% - 5px);

          @include transform(rotate(45deg));

          @include responsive-down(mobile) {
            top: calc(50% - 8px);
          }
        }

        .menu-burger__mid {
          @include transform(rotate(-45deg));
        }

        .menu-burger__bot {
          display: none;
          opacity: 0;
        }
      }

      .menu-box {
        left: auto;
        right: 0;
        transition-duration: 1s;

        &__item {
          transform: translateY(0);
          opacity: 1;
          display: inline-block;

          @include transition (all 0.4s ease-in 0.5s);
        }
      }
    }

    &:hover {
      &::before {
        opacity: 1;
        transform: scale(1);

        @include transition (all 0.5s ease 0s);
      }

      .menu-burger {
        &__top,
        &__mid,
        &__bot {
          background-color: white;
        }
      }
    }
  }
}

body {
  main {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: fixed;
      background-color: $black02;
      opacity: 0;
      z-index: -1;
      top: 0;
      transition-duration: 0.8s;
    }
  }

  &.menu--opened {
    .header {
      &__brand {
        .cls-1 {
          @include responsive-down(mobile-large) {
            fill: black !important;
          }
        }
      }
    }

    main {
      &::before {
        opacity: 1;
        z-index: 1;
        transition-duration: 0.8s;
      }
    }
  }
}
