/* Layouts: Pages: Critical Contact
--------------------------------------------------------------------------------------------------------------------- */
.contactinfo {
  display: flex;
  justify-content: space-between;

  @include responsive-down(mobile-extra-large) {
    display: block;
  }

  .titlePage {
    margin: 0;
  }

  &__subtitle {
    margin: 0;
  }

  &__form {
    width: 55%;
    margin: 0 80px 0 0;

    @include responsive-down(tablet) {
      margin: 0 40px 0 0;
      width: 50%;
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
    }

    .forms {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0 0;

      @include responsive-down(mobile-extra-large) {
        margin: 10px 0 0;
      }

      &-group {
        margin: 10px;
        width: calc(100% / 2 - 20px);

        &:nth-child(2n+1) {
          margin: 10px 10px 10px 0;
        }

        &:nth-child(2n) {
          margin: 10px 0 10px 10px;
        }
      }

      .textarea {
        margin: 40px 0 20px;

        @include responsive-down(mobile-extra-large) {
          margin: 30px 0 20px;
        }
      }

      .checkbox {
        width: 100%;
      }
    }
  }

  &__location {
    width: 400px;

    @include responsive-down(tablet) {
      width: 50%;
    }

    @include responsive-down(tablet-extra-small) {
      margin: 0;
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      margin: 40px 0 0;
    }

    p {
      margin: 0;
      color: $text-color;
    }

    a {
      color: $primary;
      font-weight: 700;
      display: block;
      margin: 8px 0;

      &:hover {
        color: $mid-grey;
      }
    }

    a.number {
      margin: 20px 0 8px;
    }
  }

  &__map {
    width: 100%;
    height: 300px;
    margin: 30px 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
