/* Common: Extends
--------------------------------------------------------------------------------------------------------------------- */
.scroll-container {
  position: absolute;
  overflow: hidden;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  width: 100%;
}

main.main {
  margin: 87px 0 0;

  @include responsive-down(mobile) {
    margin: 67px 0 0;
  }

  &.collection {
    margin: 132px 0 0;

    @include responsive-down(mobile) {
      margin: 112px 0 0;
    }
  }
}

/* TITLES
--------------------------------------------------------------------------------------------------------------------- */
.titlePage {
  font-size: 28px;
  font-weight: 400;
  margin: 0 0 10px;
}

.titleh2 {
  font-size: 26px;
  line-height: 1.2;

  @include responsive-down(mobile) {
    font-size: 24px;
  }

  @include responsive-down(mobile-small) {
    font-size: 23px;
  }

  @include responsive-down(mobile-extra-small) {
    font-size: 20px;
  }
}

/* CURSOR
--------------------------------------------------------------------------------------------------------------------- */
.cursor {
  position: fixed;
  display: block;
  width: 0;
  top: 0;
  left: 0;
  z-index: 2000;
  pointer-events: none;
  will-change: transform;

  @include responsive-down(tablet) {
    opacity: 0;
    visibility: hidden;
    display: none;
  }

  &__follow {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid $primary;
    margin-left: 0;
    margin-top: 0;
    transform-origin: 50% 50%;
    will-change: transform;
    opacity: 1;

    $voitrans: opacity 0.3s ease-in-out;

    @include transition($voitrans);
  }

  &__dot {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $primary;
    margin-left: 0;
    margin-top: 0;
    transform-origin: 50% 50%;
    will-change: transform;
    $voitrans: width 0.2s ease-in-out, height 0.2s ease-in-out;

    @include transition($voitrans);
  }

  &__carrousel {
    position: absolute;
    opacity: 0;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $primary;
    margin-left: 0;
    margin-top: 0;
    transform-origin: 50% 50%;
    will-change: transform;
    z-index: 10;
    $voitrans: width 0.2s ease-in-out, height 0.2s ease-in-out;

    @include transition($voitrans);

    &::before {
      content: "\e901";
      font-family: $icon;
      position: absolute;
      color: white;
      top: 14px;
      left: 5px;
      font-size: 21px;
    }

    &::after {
      content: "\e902";
      font-family: $icon;
      position: absolute;
      color: white;
      top: 14px;
      right: 5px;
      font-size: 21px;
    }
  }

  &__form {
    position: absolute;
    opacity: 0;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $primary;
    margin-left: 0;
    margin-top: 0;
    transform-origin: 50% 50%;
    will-change: transform;
    $voitrans: width 0.2s ease-in-out, height 0.2s ease-in-out;

    @include transition($voitrans);
  }

  &--active {
    .cursor__follow {
      opacity: 0;
    }

    .cursor__dot {
      width: 25px;
      height: 25px;
      opacity: 0.6;
    }
  }

  &--active2 {
    .cursor__carrousel {
      opacity: 1;
    }
  }

  &--active3 {
    .cursor__form {
      width: 7px;
      height: 7px;
      opacity: 0.6;
    }

    .cursor__follow,
    .cursor__dot {
      opacity: 0;
    }
  }
}

/* BUTTONS
--------------------------------------------------------------------------------------------------------------------- */
.btn {
  border: 1px solid $primary;
  padding: 13px 40px;
  font-size: 13px;
  display: inline-block;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    z-index: 0;

    @include transition(all 0.3s ease-in);
  }

  &__text {
    display: block;
    padding: 0 0 2px;
    position: relative;
    overflow: hidden;

    .btnspan {
      display: block;
      position: relative;
      transform-origin: left top;

      @include transition(transform 0.9s cubic-bezier(0.19, 1, 0.22, 1));

      &::after {
        content: attr(data-text);
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        transform-origin: left top;

        @include transition(transform 0.9s cubic-bezier(0.19, 1, 0.22, 1));
      }
    }
  }

  &:hover {
    .btn__text {
      .btnspan {
        @include transform(translateY(-100%) skewY(-10deg));

        &::after {
          @include transform(skewY(10deg));
        }
      }
    }
  }

  &--primary {
    color: $primary;
    position: relative;
    overflow: hidden;

    &::before {
      background-color: $primary;

      @include transition(all 0.3s ease-in);
    }

    &:hover {
      color: $white;

      &::before {
        height: 100%;
        z-index: -1;

        @include transition(all 0.3s ease-in);
      }
    }
  }

  &--white {
    border: 1px solid $white;
    color: white;

    &::before {
      background-color: $white;

      @include transition(all 0.3s ease-in);
    }

    &:hover {
      color: $primary;

      @include transition (all 0.5s ease-in);

      &::before {
        height: 100%;
        z-index: 0;

        @include transition (all 0.3s ease-in);
      }
    }
  }

  &--line {
    position: relative;
    border: none;
    padding: 0;
    color: $primary;
    font-size: 14px;

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      display: block;
      bottom: -4px;
      background-color: $primary;
      transition: transform 0.9s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &::before {
      transition-delay: 0s;
      transform: scaleX(0);
      transform-origin: left;
    }

    &::after {
      transition-delay: 0.25s;
      transform-origin: right;
    }

    &:hover {
      &::before {
        transition-delay: 0.25s;
        transform: scaleX(1);
      }

      &::after {
        transition-delay: 0s;
        transform: scaleX(0);
      }
    }
  }
}

/* FORMS
--------------------------------------------------------------------------------------------------------------------- */
.forms {
  &-group {
    height: 44px;
    border: 1px solid white;
    position: relative;
    font-size: 14px;
    color: $primary;
    z-index: 0;
    border-bottom: 1px solid $mid-grey;
    background-color: transparent;

    .input {
      width: 100%;
      height: 100%;
      border: 0;
      outline: none;
      padding: 10px 24px 0;
      padding-left: 8px;
      font-size: 14px;
      opacity: 0.8;
      color: $primary;
      z-index: 2;
      position: relative;
    }

    .label {
      display: inline;
      padding: 8px 4px 8px;
      box-sizing: content-box;
      position: absolute;
      top: 6px;
      left: 5px;
      transition: top 0.2s, font-size 0.2s;
      color: $primary;
      z-index: 2;
      font-size: 16px;
    }

    &--error {
      color: $warning;
      border-bottom: 1px solid $warning;

      .label {
        color: $warning;
      }
    }
  }

  &-msg {
    display: none;
    align-items: center;
    margin: 0 20px;

    &--error {
      color: $warning;
      display: inline-flex;
    }

    &--info {
      color: $primary;
      display: inline-flex;
    }
  }
}

.input.input.input--focused + .label.label,
.input.input:focus + .label {
  top: -2px;
  font-size: 9px;
}

/* Common: CHECKBOX
--------------------------------------------------------------------------------------------------------------------- */

.checkbox {
  display: block;
  position: relative;
  min-height: 40px;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    opacity: 0;
    position: absolute;
    left: -1px;
    top: 3px;
    z-index: 2;
  }

  label {
    position: relative;
    color: $primary;
    font-size: 12px;
    display: inline-flex;
    min-height: 20px;
  }

  span {
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      background-color: transparent;
      border: 1px solid $primary;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 7px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  input:checked + span::after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 6px;
    width: 5px;
    height: 8px;
    border: solid $primary;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

/* CHECKBOX RADIO-------------------------------------------------------------------------------------------------------------- */

.radio {
  display: block;
  margin-bottom: 15px;

  &-input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  &-label {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 12px;

    &::before {
      content: '';
      border: 1px solid $primary;
      padding: 7px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 50px;
    }
  }

  input[type="radio"] {
    &:checked {
      & + label::after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 50px;
        background-color: $primary;
      }
    }
  }
}

/* Common: BANNER
--------------------------------------------------------------------------------------------------------------------- */
.banner {
  width: 100%;
  height: 260px;
  position: relative;
  display: flex;
  align-items: center;
  margin: 60px 0 0;

  @include responsive-down(mobile) {
    height: 325px;
    align-items: flex-start;
  }

  &::before {
    content: "";
    background-color: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__img {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .img {
      width: 100%;
      height: 100%;
      display: grid;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    width: 100%;

    @include responsive-down(mobile) {
      padding: 40px 20px;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include responsive-down(mobile) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .btn {
      padding: 13px 40px;
    }
  }

  .content {
    &-intro {
      max-width: 440px;

      @include responsive-down(mobile-extra-large) {
        max-width: 300px;
      }

      @include responsive-down(mobile) {
        max-width: 400px;
        margin: 0 0 30px 0;
      }

      &__title,
      &__text {
        color: white;
      }

      &__text {
        margin: 0;
      }
    }
  }
}

/* Common: LOAD IMAGES
--------------------------------------------------------------------------------------------------------------------- */
.js-fadeleft {
  &__image {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: grid;
    object-fit: cover;
    transform: scale(1.5) translateX(125%);
    opacity: 0.5;

    @include transition (all 2s ease 0s);

    img {
      width: 100%;
      height: 100%;
      display: grid;
      object-fit: cover;
    }
  }

  &--active {
    &.js-fadeleft {
      &::before {
        transition-delay: 0.5s;
        transform: translateX(0);
      }
    }

    .js-fadeleft__image {
      opacity: 1;
      transition-delay: 1s;
      transform: scale(1) translateX(0);
    }
  }
}

.js-faderight {
  &__image {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: block;
    object-fit: cover;
    transform: scale(1.5) translateX(-125%);
    opacity: 0.5;

    @include transition (all 2s ease 0s);

    img {
      width: 100%;
      height: 100%;
      display: grid;
      object-fit: cover;
    }
  }

  &--active {
    &.js-faderight {
      &::before {
        transition-delay: 0.5s;
        transform: translateX(0);
      }
    }

    .js-faderight__image {
      opacity: 1;
      transition-delay: 1s;
      transform: scale(1) translateX(0);
    }
  }
}

.js-fadetop {
  &__image {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: block;
    object-fit: cover;
    transform: scale(1.5) translateY(-125%);
    opacity: 0.5;

    @include transition (all 2s ease 0s);

    img {
      width: 100%;
      height: 100%;
      display: grid;
      object-fit: cover;
    }
  }

  &--active {
    &.js-fadetop {
      &::before {
        transition-delay: 0.5s;
        transform: translateX(0);
      }
    }

    .js-fadetop__image {
      opacity: 1;
      transition-delay: 1s;
      transform: scale(1) translateX(0);
    }
  }
}

/* Common: ITEMS PROJECTS
--------------------------------------------------------------------------------------------------------------------- */
.item-projects {
  width: 100%;
  position: relative;
  display: inline-table;
  margin: 0 20px 0 0;
  vertical-align: top;
  cursor: pointer;

  p {
    margin: 0;
  }

  &__img {
    width: 100%;
    height: 400px;
    overflow: hidden;

    @include transition (all 1s ease 0s);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include transition (all 1.5s ease 0s);
    }
  }

  &:hover {
    .item-projects__img {
      box-shadow: 8px 0 16px 0 rgba(0, 0, 0, 0.1);

      img {
        transform: scale(1.1);

        @include transition (all 1s ease 0s);
      }
    }
  }

  &__title {
    color: black;
    margin: 7px 0 0;
    line-height: 1.5;

    @include responsive-down(mobile-small) {
      font-size: 20px;
    }

    @include responsive-down(mobile-extra-small) {
      font-size: 18px;
    }
  }

  &__location {
    font-size: 13px;
    line-height: 1.4;
    margin: 3px 0 0 !important;
  }

  &__description {
    padding: 14px 0 0 0;

    p {
      font-size: 12px;
      line-height: 19px;
      color: $primary;

      @include responsive-down(mobile-small) {
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
}

/* Common: ITEMS COLECCTIONS
--------------------------------------------------------------------------------------------------------------------- */
.item-collection {
  position: relative;
  display: flex;
  flex-direction: column;

  &__img {
    position: relative;
    margin: 0 0 10px;
    overflow: hidden;
    width: 100%;
    height: auto;

    &::before {
      content: "";
      float: left;
      width: 100%;
      padding-top: 100%;
      background-color: grey;
      position: relative;
      z-index: 9;
      opacity: 0.1;
    }

    .img {
      width: 80%;
      height: 80%;
      object-fit: cover;
      display: block;
      position: absolute;
      top: 10%;
      left: 10%;

      @include transition (all 1.5s ease 0s);

      &--full {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .btn-search.btn-search {
      position: absolute;
      // bottom: 15px;
      // right: 10px;
      z-index: 50;
      padding: 25px;
      // opacity: 0;
      font-size: 18px;
      height: 20px !important;
      background-color: transparent;
      cursor: pointer;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;

      @include transition (all 0.5s ease 0s);

      @include responsive-down(tablet) {
        opacity: 1;
      }

      &::before {
        display: none;
      }

      .icon-search {
        padding-bottom: 10px;
        padding-right: 10px;
      }
    }
  }

  &__content {
    p {
      display: inline-block;
      margin: 0;
    }

    .infor {
      &-identification {
        text-transform: uppercase;
        font-weight: 100;
        font-style: italic;
        margin: 0;
        font-size: 16px;
      }

      &-description {
        font-size: 12px;
        line-height: 1.6;
        margin: 5px 0 10px;
        display: block;
      }
    }
  }

  &__btns {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin: auto 0 0;
  }

  &__onebtn {
    border: none;
    position: relative;
    color: $primary;
    padding: 0;
    font-family: $font-urw !important;
    font-size: 14px;
    font-weight: 600;

    i {
      padding: 0 0 0 5px;
      font-size: 12px;
    }

    &::before {
      content: "";
      width: 0;
      height: 1px;
      background-color: $primary;
      position: absolute;
      bottom: 0;
      left: 0;

      @include transition (all 0.4s ease 0s);

      @include responsive-down(mobile-large) {
        width: 100%;
      }
    }

    &:hover {
      background-color: transparent;
      color: $mid-grey;

      &::before {
        width: 100%;
      }
    }
  }

  @include responsive-up(tablet) {
    &:hover {
      .item-collection__img {
        .img {
          transform: scale(1.1);

          @include transition (all 1s ease 0s);
        }
      }

      .btn-search {
        opacity: 1;
        // background-color: transparent;
        color: $black;
      }
    }
  }

  @include responsive-down(tablet) {
    &:focus {
      .item-collection__img {
        .img {
          transform: scale(1.1);

          @include transition (all 1s ease 0s);
        }
      }

      .btn-search {
        opacity: 1;
        background-color: transparent;
        color: $black;
      }
    }
  }

  .tns-outer {
    position: relative;
    width: 100%;

    button {
      height: 15px;
      width: 20px;
      padding: 0;
      margin: 0 4px 0;
      border: none;
      position: relative;

      &::before {
        content: "";
        height: 2px;
        width: 20px;
        background-color: white;
        position: absolute;
        left: 0;
        bottom: 7px;
      }
    }

    button[data-action="stop"],
    [data-action="start"] {
      display: none;
    }
  }

  .tns-nav {
    position: absolute;
    bottom: 18px;
    left: 45px;
    width: calc(100% - 90px);
    text-align: center;
    z-index: 1;
    border: none;

    .tns-nav-active {
      &::before {
        content: "";
        background-color: $primary;
      }
    }
  }
}

/* ANIMATIONS -------------------------------------------------------------------------------------------------------------- */

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
