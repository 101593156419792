/* Layouts: Pages: Critical Colletion
--------------------------------------------------------------------------------------------------------------------- */
.collectionnav {
  width: 100%;
  height: 45px;
  background-color: $menu-collection;
  backdrop-filter: saturate(180%) blur(20px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  z-index: 4;
  top: 87px;

  @include transition (all 0.15s ease-in);

  @include responsive-down(tablet) {
    overflow: scroll;
    justify-content: flex-start;
  }

  @include responsive-down(mobile) {
    top: 67px;
  }

  &--scroll {
    top: 70px;
  }

  &__list {
    display: inline-flex;
    letter-spacing: 1px;
    align-items: center;
    list-style: none;

    @include responsive-down(tablet) {
      padding: 0 30px;
    }

    @include responsive-down(mobile-large) {
      padding: 0 20px;
    }
  }

  &__item {
    padding: 0 35px 0;
    letter-spacing: 1px;
    font-size: 14px;

    @include responsive-down(tablet) {
      padding: 0 70px 0 0;
    }

    @include responsive-down(mobile-large) {
      padding: 0 50px 0 0;
    }

    &--active {
      a {
        color: $light-grey;
      }
    }

    a {
      color: $black;
      font-weight: 400;
      position: relative;
      white-space: nowrap;

      &::before {
        content: "";
        width: 0;
        height: 1px;
        background-color: $primary;
        position: absolute;
        bottom: 0;
        left: 0;

        @include transition (all 0.4s ease 0s);
      }

      @include responsive-up(tablet) {
        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
    }

    &.active {
      a {
        &::before {
          width: 100%;
        }
      }
    }
  }

  &--fixed {
    backdrop-filter: saturate(180%) blur(20px);
  }
}
