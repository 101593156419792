/* Common: Classes
--------------------------------------------------------------------------------------------------------------------- */

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  @include responsive-down(tablet) {
    text-rendering: auto;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

.clearfix {
  clear: both;
  float: none;
  margin: 0;
  padding: 0;
  height: 0;
}

iframe {
  border: 0 solid transparent;
  width: 100%;
  height: 100%;
}

select,
textarea,
input[type=text] {
  appearance: none;
  border-radius: 5px;
  border: none;
  box-shadow: none;
}

/* GENERALES */

body {
  background: white;
  margin: 0;
  position: relative;
  font-family: $font-urw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  margin: 0;
  padding: 0;
}

/* ENCABEZADOS */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: $font-operetta;
  color: $primary;
}

h1,
.h1 {
  font-size: 32px;
  font-weight: 500;
}

h2,
.h2 {
  font-size: 28px;
  font-weight: 400;
}

h3,
.h3 {
  font-size: 24px;
  font-weight: 400;
}

h4,
.h4,
h5,
.h5 {
  font-size: 19px;
  font-weight: 500;
}

p {
  font-family: $font-urw;
  color: $primary;
  font-weight: 400;
  line-height: 1.8;
  font-size: 16px;

  @include responsive-down(mobile-large) {
    font-size: 15px;
  }

  @include responsive-down(mobile-small) {
    font-size: 14px;
  }
}

a {
  text-decoration: none;
  color: $mid-grey;

  @include transition (all 0.2s ease-in);

  &:hover {
    color: $primary;

    @include transition (all 0.2s ease-in);
  }
}

/* LISTAS */

ol {
  margin: 0;
  padding: 0 20px 0;
  list-style: none;
  counter-reset: li;

  li {
    padding: 0 0 6px 0;
    counter-increment: li;
    font-weight: 500;
    color: $primary;

    &::before {
      content: counter(li);
      display: inline-block;
      margin-right: 13px;
      color: $primary;
    }
  }
}

ul {
  list-style: disc;

  li {
    padding: 0 0 6px 0;
    text-decoration: none;
    font-weight: 500;
    color: $primary;

    &::marker {
      color: $primary;
    }
  }
}

button {
  border: 1px solid $primary;
  background-color: transparent;
  color: $primary;
  padding: 13px 26px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

/* FORMS */

input {
  padding: 15px;
  font-size: 14px;
  color: $primary;
}

label {
  color: $primary;
}

:active,
:focus {
  border: none;
  outline: none;
  text-decoration: none;
}

.form-block {
  max-width: 300px;
}

input[type="submit"] {
  border: none;
  border-radius: 0;
  width: 100%;

  @include transition (all 0.2s ease 0s);

  &:hover {
    @include transition (all 0.2s ease 0s);
  }
}

input[type="search"] {
  border: none;
  border-radius: 0;
  width: 100%;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"] {
  border: none;
  border-radius: 0;
  width: 100%;

  @include transition (border-bottom 0.2s ease 0s);

  &:focus {
    color: inherit;
  }

  &:hover {
    @include transition(background-color 0.2s ease 0s);
  }

  &.disabled {
    &:hover {
      color: inherit;
    }

    opacity: 0.5;
  }
}

input[type="date"],
input[type="number"],
input[type="time"] {
  width: 100%;

  @include transition (all 0.2s ease 0s);
}

input[type="number"] {
  max-width: 60px;
  margin-left: 10px;
}

textarea[rows] {
  height: auto;
}

textarea {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 0;
  font-size: 14px;
  border: 1px solid $primary;
  font-family: $font-urw;

  &:focus {
    border: 1px solid $mid-grey;
  }
}

/* TABLES */
table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding-top: 10px;
    color: $primary;
  }

  .table_center {
    text-align: right;
  }

  th {
    text-align: left;
  }
}

::selection {
  background: $light-grey;
  text-shadow: none;
  color: $mid-black;
}
