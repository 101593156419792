/* Layouts: Pages: Critical Project
--------------------------------------------------------------------------------------------------------------------- */
.projectcard {
  .banner {
    @include responsive-down(mobile-large) {
      margin: 40px 0 0;
    }
  }

  .tns-outer {
    button {
      display: none;
    }
  }
}

.projcardintro {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 50px;

  @include responsive-down(desktop-small) {
    padding: 20px 0 30px;
  }

  @include responsive-down(tablet) {
    justify-content: flex-start;
    flex-direction: column;
  }

  @include responsive-down(mobile-large) {
    padding: 10px 0 30px;
  }

  .metadata,
  .name,
  .info {
    margin: 0;

    @include responsive-down(mobile) {
      line-height: 20px;
    }
  }

  .metadata {
    @include responsive-down(mobile) {
      font-size: 14px;
    }

    @include responsive-down(mobile-small) {
      font-size: 12px;
    }

    @include responsive-down(mobile-extra-small) {
      font-size: 11px;
    }
  }

  .info {
    font-family: $font-operetta;
    font-size: 20px;

    @include responsive-down(mobile) {
      font-size: 16px;
    }
  }

  &__title {
    .name {
      font-size: 36px;
      line-height: 1.3;

      @include responsive-down(tablet-extra-small) {
        font-size: 32px;
      }

      @include responsive-down(mobile-extra-large) {
        font-size: 30px;
      }

      @include responsive-down(mobile-large) {
        font-size: 28px;
      }

      @include responsive-down(mobile) {
        font-size: 24px;
      }

      @include responsive-down(mobile-small) {
        font-size: 18px;
      }
    }
  }

  &__cardcontent {
    display: flex;

    @include responsive-down(mobile) {
      flex-direction: column;
    }

    .cardcontent__item {
      padding: 0 0 0 80px;

      @include responsive-down(tablet-large) {
        padding: 0 0 0 40px;
      }

      @include responsive-down(tablet) {
        padding: 10px 0 0 40px;
      }

      @include responsive-down(mobile) {
        padding: 10px 0 0 0;
      }

      &:first-child {
        padding: 0;

        @include responsive-down(tablet) {
          padding: 10px 0 0 0;
        }
      }
    }
  }
}

.projcardintrocarrousel {
  width: 100%;
  overflow-x: auto;
  display: block;
  white-space: nowrap;
  padding: 0 0 50px;

  @include responsive-down(mobile) {
    padding: 0 0 50px 20px;
  }

  @include responsive-down (mobile-extra-large) {
    padding: 0 0 30px 20px;
  }

  &__img {
    width: 100%;
    height: 550px;
    object-fit: cover;
    overflow: hidden;
    display: inline-block;
    position: relative;
    vertical-align: top;
    // margin: 0 0 0 10px;

    @include responsive-down(tablet-extra-large) {
      height: calc(100% - 100px);
    }

    @include responsive-down(mobile-large) {
      height: 500px;
      // width: 90%;
    }

    @include responsive-down(mobile) {
      height: 370px;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include transition (all 1.5s ease 0s);
    }
  }
}

.projcard-scroll {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  height: 50px;
  margin-top: -75px;

  @include responsive-down(mobile-extra-large) {
    margin-top: -55px;
  }

  &__link {
    position: absolute;
    bottom: -10px;
    left: auto;
    right: auto;
    width: 30px;
    height: 70px;
    display: flex;
    justify-content: center;

    &::after {
      content: "";
      width: 2px;
      height: 70px;
      background-color: #919191;
      position: absolute;
      top: 0;
      left: auto;
      right: auto;
      z-index: 2;
      margin: auto;
    }

    .loading_line {
      position: relative;
      top: 0;
      left: 0;
      bottom: auto;
      right: auto;
      margin: auto;
      width: 2px;
      height: 100%;
      z-index: 4;
      transform-origin: 0% 100%;
      animation: kf_loading_line 2s ease 0s infinite;

      .loading_line_inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        right: auto;
        width: 100%;
        height: 100%;
        background-color: #e6e6e6;
        transform-origin: 0% 0%;
      }

      .loading_line_inner--1 {
        opacity: 0.2;  //change for inner line opacity, currently 0/hidden
        animation: kf_loading_line_inner-1 2s ease 0s infinite;
      }

      .loading_line_inner--2 {
        opacity: 1;
        animation: kf_loading_line_inner-2 2s ease 0s infinite;
      }
    }

    @keyframes kf_loading_line {
      0% {
        transform: scaleY(1);
      }

      50% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(0);
      }
    }

    @keyframes kf_loading_line_inner-1 {
      0% {
        transform: scaleY(0);
      }

      25% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(1);
      }
    }

    @keyframes kf_loading_line_inner-2 {
      0% {
        transform: scaleY(0);
      }

      25% {
        transform: scaleY(0);
      }

      50% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(1);
      }
    }
  }
}
