/* Layouts: Pages: Critical Home
--------------------------------------------------------------------------------------------------------------------- */
.home {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include transition (all 1.5s ease 0s);
  }
}

.homeintro {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @include responsive-up(tablet-extra) {
    height: 90vh;
  }

  &__link {
    position: absolute;
    bottom: -40px;
    left: auto;
    right: auto;
    width: 30px;
    height: 140px;
    display: flex;
    justify-content: center;

    &::after {
      content: "";
      width: 2px;
      height: 140px;
      background-color: #919191;
      position: absolute;
      top: 0;
      left: auto;
      right: auto;
      z-index: 2;
      margin: auto;
    }

    .loading_line {
      position: relative;
      top: 0;
      left: 0;
      bottom: auto;
      right: auto;
      margin: auto;
      width: 2px;
      height: 100%;
      z-index: 4;
      transform-origin: 0% 100%;
      animation: kf_loading_line 2s ease 0s infinite;

      .loading_line_inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        right: auto;
        width: 100%;
        height: 100%;
        background-color: #e6e6e6;
        transform-origin: 0% 0%;
      }

      .loading_line_inner--1 {
        opacity: 0.2;  //change for inner line opacity, currently 0/hidden
        animation: kf_loading_line_inner-1 2s ease 0s infinite;
      }

      .loading_line_inner--2 {
        opacity: 1;
        animation: kf_loading_line_inner-2 2s ease 0s infinite;
      }
    }

    @keyframes kf_loading_line {
      0% {
        transform: scaleY(1);
      }

      50% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(0);
      }
    }

    @keyframes kf_loading_line_inner-1 {
      0% {
        transform: scaleY(0);
      }

      25% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(1);
      }
    }

    @keyframes kf_loading_line_inner-2 {
      0% {
        transform: scaleY(0);
      }

      25% {
        transform: scaleY(0);
      }

      50% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(1);
      }
    }
  }

  &__list {
    height: 100%;
    width: 100%;
    align-self: flex-start;
  }

  #tns1-iw {
    height: 100vh;
  }

  /**
 * ----------------------------------------
 * animation scale-down-center
 * ----------------------------------------
 */
  @-webkit-keyframes scale-down-center {
    0% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    -webkit-animation: scale-down-center 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-down-center 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      background: radial-gradient(circle, black 0%, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0.4) 100%);
    }
  }

  &__content {
    position: absolute;
    margin: auto;
    text-align: center;
    height: auto;
    z-index: 0;

    @include responsive-down(mobile) {
      padding: 0 20px;
    }

    @include responsive-down(mobile) {
      text-align: left;
      left: 0;
      height: 28vh;
    }

    &--active {
      transform: translateY(0);
      opacity: 1;

      @include transition (all 0.4s ease-in 1s);
    }
  }

  /**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  */
  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-top-subtitle {
    0% {
      -webkit-transform: translateY(60px);
      transform: translateY(60px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  .titlelines {
    -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    &__next {
      -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s both;
      animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s both;
    }

    &__next2 {
      -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
      animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
    }

    &__last {
      -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s both;
      animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s both;
    }
  }

  &__title {
    font-weight: 300;
    max-width: 620px;
    text-align: center;
    color: white;
    line-height: 1.2;
    font-size: 38px;
    font-family: $font-operetta;
    -webkit-animation: slide-top 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    @include responsive-down(tablet-small) {
      font-size: 34px;
      max-width: 610px;
    }

    @include responsive-down(tablet-extra-small) {
      font-size: 32px;
      max-width: 550px;
    }

    @include responsive-down(mobile-large) {
      font-size: 30px;
      max-width: 520px;
    }

    @include responsive-down(mobile) {
      font-size: 26px;
      max-width: 340px;
      line-height: 38px;
      text-align: left;

      .titlelines {
        display: inline;
      }
    }

    @include responsive-down(mobile-extra-small) {
      font-size: 22px;
      max-width: 270px;
      line-height: 29px;
    }
  }

  &__subtitle {
    color: white;
    -webkit-animation: slide-top-subtitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s both;
    animation: slide-top-subtitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s both;

    @include responsive-down(tablet-extra-small) {
      font-size: 14px;
    }

    @include responsive-down(mobile) {
      font-size: 15px;
    }
  }

  .tns-nav {
    position: absolute;
    right: -50px;
    top: 44%;
    left: auto;
    z-index: 40;
    border: none;

    @include transform(rotate(90deg));
  }

  .tns-outer {
    z-index: -1;

    button {
      height: 2px;
      width: 40px;
      padding: 0;
      margin: 0 4px 0;
      border: none;
    }

    button[data-action="stop"] {
      display: none;
    }
  }
}
