/* Variables: Fonts: Icomoon
--------------------------------------------------------------------------------------------------------------------- */
@font-face {
  font-family: 'icomoon';
  src: url('/assets/css/fonts/icomoon.eot?av7k9b');
  src:
    url('/assets/css/fonts/icomoon.eot?av7k9b#iefix') format('embedded-opentype'),
    url('/assets/css/fonts/icomoon.ttf?av7k9b') format('truetype'),
    url('/assets/css/fonts/icomoon.woff?av7k9b') format('woff'),
    url('/assets/css/fonts/icomoon.svg?av7k9b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down::before {
  content: "\e900";
}

.icon-arrow-left::before {
  content: "\e901";
}

.icon-arrow-right::before {
  content: "\e902";
}

.icon-arrow-up::before {
  content: "\e903";
}

.icon-eco::before {
  content: "\e904";
}

.icon-elements::before {
  content: "\e905";
}

.icon-insta::before {
  content: "\e906";
}

.icon-linkedin::before {
  content: "\e907";
}

.icon-materials::before {
  content: "\e908";
}

.icon-packaging::before {
  content: "\e909";
}

.icon-recycling::before {
  content: "\e90a";
}

.icon-search::before {
  content: "\e90b";
}

.icon-phone::before {
  content: "\e90c";
}

.icon-mail::before {
  content: "\e90e";
}

.icon-collection::before {
  content: "\e90f";
}

.icon-plus::before {
  content: "\e90d";
}

.icon-copylink::before {
  content: "\e910";
}

.icon-download::before {
  content: "\e911";
}
