/* Layouts: Pages: Critical PRODUCTION
--------------------------------------------------------------------------------------------------------------------- */
.productintro {
  width: 100%;
  height: 525px;
  position: relative;
  display: flex;
  align-items: flex-start;

  @include responsive-down(mobile-extra-large) {
    margin: 0 0 60px;
  }

  &::before {
    content: "";
    background-color: $black02;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @include responsive-down(mobile-large) {
    height: 500px;
    align-items: flex-start;
  }

  &__img {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      display: grid;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    padding: 140px 0 0;

    .btn {
      padding: 13px 40px;
    }

    @include responsive-down(mobile-large) {
      padding: 20px 0 0;
    }
  }

  &__title,
  &__text {
    color: white;
  }

  &__title {
    font-size: 38px;

    @include responsive-down(mobile-extra-large) {
      font-size: 36px;
    }

    @include responsive-down(mobile-large) {
      font-size: 34px;
    }

    @include responsive-down(mobile) {
      font-size: 30px;
    }

    @include responsive-down(mobile-extra-small) {
      font-size: 26px;
    }
  }

  &__text {
    margin: 0;
    max-width: 490px;
  }
}
